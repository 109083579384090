import { Link } from "react-router-dom";
import { Title } from "../components";

function Works() {
  const works_images = [
    "../assets/img/works_img1.png",
    "../assets/img/works_img2.png",
    "../assets/img/works_img3.png",
    "../assets/img/works_img4.png",
    "../assets/img/works_img5.png",
    "../assets/img/works_img6.png",
    "../assets/img/works_img7.png",
    "../assets/img/works_img8.png",
  ];

  return (
    <div id="works">
      <div className="container">
        <Title title={"WORKS"} />
        <ul className="works_img_wrap">
          {works_images.map((e, i) => (
            <Link to={`/works_info/${i}`} key={i}>
              <object data={e} className="works_img">
                img
              </object>
            </Link>
          ))}
        </ul>
        <div className="works_text">
          앱 서비스 개발 / 운영 전문가 그룹이{" "}
          <br className="mobile_works_text" />
          필요하시다면 (주)이끌고에 문의해주세요
        </div>
        <div className="mobile_works_divide"></div>
      </div>
    </div>
  );
}

export default Works;
