import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Inquire, Main, Nav, WorksInfo } from "./components";

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route exact path="/:id" element={<Main />} />
        <Route path="/inquire" element={<Inquire />} />
        <Route path="/works_info/:id" element={<WorksInfo />} />
        <Route path="*" element={<Main />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
