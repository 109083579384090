import React from "react";

function TopService({ title, img, num }) {
  return (
    <div id="top_service">
      <object data={img} className="top_service_img">
        img
      </object>
      <div className="mobile_num">{num}</div>
      <div className="top_service_title">
        {title[0]}
        <br className="top_service_mobile_br" />
        {title[1]}
      </div>
    </div>
  );
}

export default TopService;
