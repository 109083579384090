import React from "react";

function BottomService({ title, list, img }) {
  return (
    <div id="bottom_service">
      <object data={img} className="bottom_service_img">
        img
      </object>
      <div className="bottom_service_title">
        <em>{title[0]}</em>
        <br />
        {title[1]}
      </div>
      <ul className="bottom_service_list">
        {list.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    </div>
  );
}

export default BottomService;
