import { useEffect } from "react";
import { Banner, Service, Works, Contact, MobileButton } from "../components";
import { useParams } from "react-router-dom";

function Main() {
  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      if (id === undefined) {
        window.scroll({ top: 0 });
      } else {
        const item = document.getElementById(id);
        window.scroll({ top: item.offsetTop - 100, behavior: "smooth" });
      }
    }, 100);
  }, [id]);

  return (
    <div id="main">
      <Banner />
      <Service />
      <Works />
      <Contact />
      <MobileButton />
    </div>
  );
}

export default Main;
