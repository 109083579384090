import React from "react";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Footer() {
  const footerRef = useRef();
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname);
  const [mobileFooterMargin, setMobileFooterMargin] = useState();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (
      !path.includes("/inquire") &&
      !path.includes("/works_info") &&
      window.innerWidth <= 550
    ) {
      setMobileFooterMargin({
        marginBottom: "15rem",
      });
    } else {
      setMobileFooterMargin(null);
    }
  }, [path]);

  return (
    <footer style={mobileFooterMargin} ref={footerRef}>
      <div className="container">
        <object
          data="../assets/img/ecclgo_text_logo_white.png"
          className="footer_logo"
        >
          logo_img
        </object>
        <div className="footer_text">
          사업자 등록번호: 443-86-02005
          <br />
          대표이사: 박호종 | 주소: 서울 금천구 디지털로 9길 41 삼성 IT 해링턴
          타워 1304호
          <br />
          <br className="mobile_footer_br" />© Ecclgo Corp. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
