import React from "react";

function Title({ title }) {
  return (
    <div id="title">
      <div className="title_line"></div>
      <div className="title_text">{title}</div>
      <div className="title_line"></div>
    </div>
  );
}

export default Title;
