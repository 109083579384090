import React from "react";
import { Link } from "react-router-dom";

function MobileButton() {
  const onPdfButtonClick = () => {
    fetch("../assets/이끌고_회사소개서.pdf").then((res) => {
      res.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "이끌고_회사소개서.pdf";
        alink.click();
      });
    });
  };

  return (
    <div id="mobile_button">
      <Link to="/inquire">
        <button className="top_button">이끌고에 문의하기</button>
      </Link>
      <button className="bottom_button" onClick={onPdfButtonClick}>
        회사소개서
        <embed
          src="../assets/img/download_icon.svg"
          type="image/svg+xml"
          className="pdf_button_icon"
        />
      </button>
    </div>
  );
}

export default MobileButton;
