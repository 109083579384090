import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

function Nav() {
  gsap.registerPlugin(ScrollTrigger);
  const navRef = useRef();
  const navItemsRef = useRef();
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  // nav 스크롤 애니메이션
  useEffect(() => {
    const nav = navRef.current;
    const navItems = navItemsRef.current;
    const tl = gsap.timeline();

    if (
      window.innerWidth <= 550 &&
      (path.includes("/inquire") || path.includes("/works_info"))
    ) {
      document.querySelector(".nav_items").classList.add("displayNone");
    } else {
      document.querySelector(".nav_items").classList.remove("displayNone");
    }

    if (!path.includes("/inquire") && !path.includes("/works_info")) {
      tl.fromTo(
        nav,
        {
          backgroundColor: "transparent",
          boxShadow: "0 0 0 0",
        },
        {
          backgroundColor: "white",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          scrollTrigger: {
            trigger: nav,
            start: 100,
            end: 200,
            scrub: 1,
          },
          onComplete: function () {
            gsap.set(this.targets(), { clearProps: "all" });
          },
        }
      );

      tl.fromTo(
        navItems,
        {
          color: "white",
        },
        {
          color: "black",
          scrollTrigger: {
            trigger: navItems,
            start: 100,
            end: 200,
            scrub: 1,
          },
          onComplete: function () {
            gsap.set(this.targets(), { clearProps: "all" });
          },
        }
      );
    } else {
      ScrollTrigger.killAll();
      gsap.set(nav, { clearProps: "all" });
      gsap.set(navItems, { clearProps: "all" });
    }
  }, [path]);

  return (
    <nav ref={navRef}>
      <div className="container">
        <Link
          className="nav_logo"
          to="/"
          onClick={() => {
            window.scroll({ top: 0 });
          }}
        >
          <div className="ecclgo_logo">
            <object data="../assets/img/ecclgo_logo.png" className="web_logo">
              img
            </object>
            <object
              data="../assets/img/ecclgo_text_logo.png"
              className="mobile_logo"
            >
              img
            </object>
          </div>
        </Link>
        <ul className="nav_items" ref={navItemsRef}>
          <Link to="/service">
            <li className="nav_item">SERVICE</li>
          </Link>
          <Link to="/works">
            <li className="nav_item">WORKS</li>
          </Link>
          <Link to="/contact">
            <li className="nav_item">CONTACT</li>
          </Link>
          <Link to="/inquire">
            <li className="nav_item web_button">
              <button>문의하기</button>
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
