import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  const onPdfButtonClick = () => {
    fetch("../assets/이끌고_회사소개서.pdf").then((res) => {
      res.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "이끌고_회사소개서.pdf";
        alink.click();
      });
    });
  };

  return (
    <div id="banner">
      <div className="container">
        <div className="banner_title">
          앱 서비스 운영 / 개발 전문 그룹
          <br />
          이끌고 입니다
        </div>
        <div className="banner_subtitle">
          이끌고는 앱 서비스 운영 / 개발 전문가 그룹입니다.
          <br />
          고객의 상황에 맞는 체계적 설계와, 개발 과정을 약속드립니다.
        </div>
        <div className="banner_buttons">
          <Link to="/inquire">
            <button className="faq_button">이끌고에 문의하기</button>
          </Link>
          <button className="pdf_button" onClick={onPdfButtonClick}>
            회사 소개서
            <embed src="../assets/img/download_icon.svg" type="image/svg+xml" className="pdf_button_icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
