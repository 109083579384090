import React from "react";
import { TopService, BottomService } from "../components";

function Service() {
  const top_service = [
    {
      title: ["기능정의 및", "개발환경 분석"],
      img: "../assets/img/service_top1.png",
    },
    {
      title: ["서비스 및", "백엔드 설계"],
      img: "../assets/img/service_top2.png",
    },
    {
      title: ["디자인 및", "프론트엔드 개발"],
      img: "../assets/img/service_top3.png",
    },
    {
      title: ["모듈테스트 및", "통합 QA 테스트"],
      img: "../assets/img/service_top4.png",
    },
  ];

  const bottom_service = [
    {
      title: ["APP", "Development"],
      list: ["Native (iOS, Android)", "Hybrid Web App", "React Native"],
      img: "../assets/img/service_bottom1.png",
    },
    {
      title: ["WEB", "Development"],
      list: [
        "PC / Mobile (Responsive)",
        "ERP / LMS / GroupWare",
        "Service Admin",
      ],
      img: "../assets/img/service_bottom2.png",
    },
    {
      title: ["IT / Tech", "Consulting"],
      list: ["IT / Tech Consulting", "Out Sourcing"],
      img: "../assets/img/service_bottom3.png",
    },
  ];

  return (
    <div id="service">
      <div className="container">
        <div className="top_service service_wrap">
          <div className="service_title">
            <em>앱 / 웹 서비스</em> 개발에 고민중이신가요?
            <br />
            서비스 운영 / 개발 전문 그룹,&nbsp;
            <br className="mobile_service_br" />
            이끌고를 만나보세요
          </div>
          <ul className="service_items_wrap">
            {top_service.map((e, i) => (
              <li key={i} className="service_items">
                <TopService title={e.title} img={e.img} num={i + 1} />
              </li>
            ))}
          </ul>
        </div>
        <div className="mobile_service_divide"></div>
        <div className="bottom_service service_wrap">
          <div className="service_title">
            <em>㈜이끌고</em>는 고객의 비즈니스를
            <br />
            체계적인 방식으로 실현해드립니다
          </div>
          <ul className="service_items_wrap">
            {bottom_service.map((e, i) => (
              <li key={i} className="service_items">
                <BottomService title={e.title} list={e.list} img={e.img} />
              </li>
            ))}
          </ul>
        </div>
        <div className="mobile_service_divide"></div>
      </div>
    </div>
  );
}

export default Service;
