import React from "react";
import { Title } from "../components";

function Contact() {
  return (
    <div id="contact">
      <div className="container">
        <Title title="CONTACT" />
        <div className="contact_map"></div>
        <img
          src="../assets/img/ecclgo_text_logo.png"
          alt="map_img"
          className="contact_logo"
        />
        <ul className="contact_text_wrap">
          <li className="contact_text">
            <div>
              주소: 서울 금천구 디지털로 9길 41 삼성 IT해링턴 타워, 1304호
            </div>
            <br />
            <div>전화: 02-6264-1333</div>
            <br className="mobile_contact_br" />
            <div>
              메일: <em>ecclgo@ecclgo.com</em>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;
