import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

function Inquire() {
  // 새로고침 시 스크롤 초기화
  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scroll({ top: 0 });
    };
    window.scroll({ top: 0 });
  }, []);

  const form = useRef();

  const [isAllInfoWritten, setIsAllInfoWritten] = useState({
    inquire_title: false,
    inquire_content: false,
    inquire_email: false,
    inquire_number: false,
    inquire_checkbox: false,
  });
  const [readyToSend, setReadyToSend] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    await emailjs
      .sendForm(
        "service_bye0xm9",
        "template_qcm68x1",
        form.current,
        "6DraedzwvB5vZuMyv"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    return (window.location.href = "/");
  };

  const onInputChange = (e) => {
    if (e.target.value !== "") {
      setIsAllInfoWritten((prevState) => {
        return { ...prevState, [e.target.name]: true };
      });
    } else {
      setIsAllInfoWritten((prevState) => {
        return { ...prevState, [e.target.name]: false };
      });
    }
  };

  const onCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsAllInfoWritten((prevState) => {
        return { ...prevState, inquire_checkbox: true };
      });
    } else {
      setIsAllInfoWritten((prevState) => {
        return { ...prevState, inquire_checkbox: false };
      });
    }
  };

  const needMoreInfo = () => {
    alert("필수입력 항목을 입력해주세요");
  };

  useEffect(() => {
    if (
      isAllInfoWritten.inquire_title === true &&
      isAllInfoWritten.inquire_content === true &&
      isAllInfoWritten.inquire_email === true &&
      isAllInfoWritten.inquire_number === true &&
      isAllInfoWritten.inquire_checkbox === true
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [isAllInfoWritten]);

  return (
    <div id="inquire" className="container">
      <div className="inquire_header">
        <em>이끌고</em>에 궁금한 점이 있으신가요?
        <br />
        담당자가 확인 후 빠르게 답변 드리겠습니다
      </div>
      <div className="inquire_form_wrap">
        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="inquire_title" className="inquire_title_label">
            제목 <em>(필수입력)</em>
          </label>
          <input
            type="text"
            name="inquire_title"
            placeholder="제목을 입력해주세요"
            onChange={onInputChange}
          />
          <div className="inquire_divider"></div>
          <label htmlFor="inquire_content" className="inquire_title_label">
            문의내용 <em>(필수입력)</em>
          </label>
          <textarea
            className="inquire_textarea"
            name="inquire_content"
            placeholder="내용을 입력해주세요"
            onChange={onInputChange}
          ></textarea>
          <label htmlFor="inquire_email" className="inquire_title_label">
            이메일 주소 <em>(필수입력)</em>
          </label>
          <input
            type="email"
            name="inquire_email"
            placeholder="이메일 주소를 입력해주세요"
            onChange={onInputChange}
          />
          <div className="inquire_divider"></div>
          <label htmlFor="inquire_number" className="inquire_title_label">
            전화번호 <em>(필수입력)</em>
          </label>
          <input
            type="number"
            name="inquire_number"
            placeholder="대시(-)를 제외한 후 숫자만 입력해주세요"
            onChange={onInputChange}
          />
          <div className="inquire_divider mobile_inquire_divider"></div>
          <div className="inquire_title_label">
            개인정보 수집 및 이용에 대한 안내
          </div>
          <div className="inquire_agreement">
            <input
              type="checkbox"
              name="inquire_checkbox"
              id="inquire_checkbox"
              onChange={onCheckboxChange}
            />
            <label
              htmlFor="inquire_checkbox"
              className="inquire_checkbox"
            ></label>
            <div className="inquire_title_label">
              개인정보 수집 및 이용에 동의합니다 <em>(필수입력)</em>
            </div>
          </div>
          <button
            type={readyToSend ? "submit" : "button"}
            className="inquire_submit_button"
            onClick={readyToSend ? sendEmail : needMoreInfo}
          >
            이끌고에 문의하기
          </button>
        </form>
      </div>
    </div>
  );
}

export default Inquire;
