import React from "react";

function WorksTab({ title, tag, desc1, desc2, desc3, rect_img, img }) {
  return (
    <div id="works_tab">
      <object data={rect_img} className="mobile_rect_img">
        rect_img
      </object>
      <div className="title_tab_wrapper">
        <div className="tab_title">
          <em>{title[0]}</em>
          <br />
          {title[1]}
        </div>
        <div className="tab_tag_wrapper">
          {tag.map((e, i) => (
            <span className="tab_tag" key={i}>
              {e}
            </span>
          ))}
        </div>
      </div>
      <div className="mobile_tab_divider"></div>
      <div className="desc_tab_wrapper">
        <div className="tab_desc_title">1. 포트폴리오 소개</div>
        {desc1.map((e, i) => (
          <div className="mobile_tab_list" key={i}>
            <div className="works_tab_dot"></div>
            <li className="tab_desc">{e}</li>
          </div>
        ))}
        <div className="tab_desc_title">2. 작업 범위</div>
        {desc2.map((e, i) => (
          <div className="mobile_tab_list" key={i}>
            <div className="works_tab_dot"></div>
            <li className="tab_desc">{e}</li>
          </div>
        ))}
        <div className="tab_desc_title">3. 주요 기능</div>
        {desc3.map((e, i) => (
          <div className="mobile_tab_list" key={i}>
            <div className="works_tab_dot"></div>
            <li className="tab_desc">{e}</li>
          </div>
        ))}
      </div>
      <div className="rect_tab_wrapper">
        <object data={rect_img}>rect_img</object>
      </div>
      <div className="img_tab_wrapper">
        <object data={img}>img</object>
      </div>
    </div>
  );
}

export default WorksTab;
