import { WorksTab } from "../components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function WorksInfo() {
  const works_info_list = [
    {
      title: ["간편 이사견적 중개", "이끌래요 플랫폼"],
      tag: ["Flutter", "Android / iOS", "React", "데이터 수집 / 분류"],
      desc1: [
        "이사를 준비 중인 유저와 이사업체를 매칭시켜주는 견적형 중개서비스",
        "파트너 관리 및 정산화면을 포함한 관리자 WEB과 파트너 APP 별도 존재",
      ],
      desc2: ["Android 네이티브 앱 개발, Front-end 개발, API 연동"],
      desc3: [
        "관리점포설정, 점검 및 서비스일지 업로드",
        "GPS 기반 점포 및 기사 실시간 위치 추적, 통합관리센터 모니터 연동",
      ],
      rect_img: "../assets/img/works_img1.png",
      img: "../assets/img/works_info_img1.png",
    },
    {
      title: ["우리은행 WON", "통합 로그인"],
      tag: ["Java", "Android", "API 연동", "보안인증 솔루션"],
      desc1: [
        "자동으로 비밀번호를 생성하고 입력까지 해주는 우리은행 통합로그인",
        "사용자의 인증을 패스워드 보다 쉽게, 간편하게 해주는 인증 서비스",
      ],
      desc2: ["Android 네이티브 앱 개발, Front-end 개발, API 연동"],
      desc3: ["사용자의 인증을 진행하는 강력한 보안 솔루션"],
      rect_img: "../assets/img/works_img2.png",
      img: "../assets/img/works_info_img2.png",
    },
    {
      title: ["IoT 온도센서 연동", "냉동 관리시스템"],
      tag: ["Flutter", "Android / iOS", "React", "데이터 수집 / 분류"],
      desc1: [
        "국내 대형마트 “H“ 마트 전용 냉동 관리시스템",
        "개별 센서 연동기반 실시간 제품관리, 온도변화 측정 및 알림기능",
      ],
      desc2: [
        "IoT 온도센서와 통신단말 API 연동 후 데이터 취합",
        "어드민 관리자에서 온도 자동제어 처리",
      ],
      desc3: [
        "센서 연동 기반 실시간 온도관리, 온도변동시 관리자 알림 발송",
        "점포별 상태 통합관리, 개별 온도변화 차트",
      ],
      rect_img: "../assets/img/works_img3.png",
      img: "../assets/img/works_info_img3.png",
    },
    {
      title: ["제품 재고관리 / 발주", "맞춤형 ERP 시스템"],
      tag: ["ERP", "커스터마이징", "React", "DB 설계", "제품 재고 및 발주관리"],
      desc1: [
        "제품 재고를 관리하고, 신규로 발주할 수도 있는 맞춤형 ERP 시스템",
      ],
      desc2: [
        "DB설계, 데이터 구성 및 API 설계",
        "모듈화된 ERP 기능 설계 및 구축",
      ],
      desc3: ["거래관리, 제품재고관리, 신규 발주관리, 재고수불 관리 등"],
      rect_img: "../assets/img/works_img4.png",
      img: "../assets/img/works_info_img4.png",
    },
    {
      title: ["RFID 인식 기반", "출입기록 시스템"],
      tag: [
        "RFID 인식",
        "관리자 시스템",
        "React",
        "DB 설계",
        "태깅 이벤트 감지",
      ],
      desc1: [
        "RFID 태그 / 센서칩을 기반으로 개별 공간 출입기록을 확인하는 관리 시스템",
      ],
      desc2: [
        "RFID 기반 센서 연동 / 개별 태그 위한 외부기기 연동",
        "DB 구조 설계 및 관리자 웹페이지 제작",
      ],
      desc3: ["개별 센서 태깅 기록 저장 및 이벤트 알림 전송"],
      rect_img: "../assets/img/works_img5.png",
      img: "../assets/img/works_info_img5.png",
    },
    {
      title: ["출장정비, 견적수리", "카수리 플랫폼"],
      tag: [
        "네이티브 앱",
        "Android / iOS",
        "React",
        "입찰형 수리견적",
        "관리자 웹",
      ],
      desc1: [
        "예약을 통해 출장 차량정비, 타이어 교체, 수리견적 서비스를 제공하는 앱 서비스",
      ],
      desc2: [
        "서비스 플로우 재설계, 서비스 리뉴얼",
        "iOS/안드로이드 앱 및 관리자 페이지 기획구현",
        "알림톡 및 알림 메시지 구현, 서비스 매뉴얼 기획",
      ],
      desc3: ["출장 차량정비, 타이어 교체, 수리견적 요청, 관제용 웹페이지"],
      rect_img: "../assets/img/works_img6.png",
      img: "../assets/img/works_info_img6.png",
    },
    {
      title: ["트럭운행 및 중량관제", "Truck Caliber"],
      tag: ["IoT 센서", "DB 구조설계", "React", "실시간 통합관제"],
      desc1: [
        "운송차량에 IoT 센서를 통합하여 실시간 통신 및 이동경로를 실시간 체크",
        "차량적재물의 무게쏠림과 서스펜션 부하수준을 확인 가능한 세부정보 제공",
      ],
      desc2: [
        "프로젝트 전체의 시스템 아키텍쳐 설계",
        "데이터 전송 및 실시간 계산을 위한 소프트웨어 개발",
        "어드민 관제화면 대시보드 구축",
      ],
      desc3: [
        "IoT 센서 기반 실시간 통신,  차량 중량 및 부하 분산량 즉시확인",
        "다수차량 위치확인, 차량의 중량 및 과적 상태체크, 이동경로 통합관제",
      ],
      rect_img: "../assets/img/works_img7.png",
      img: "../assets/img/works_info_img7.png",
    },
    {
      title: ["차량호출 서비스", "CarETA"],
      tag: ["Flutter", "Android / iOS", "React", "GPS", "관리자 웹"],
      desc1: ["러시아 지역 기반의 차량호출 서비스 (우버 방식)"],
      desc2: [
        "Flutter 기반 앱 개발",
        "실시간 차량위치 GPS 안내",
        "여행경비 결제 시스템 구축",
        "예약건 및 실시간 배차관리 관제화면 구축",
      ],
      desc3: [
        "GPS 기반 차량위치 확인 , 실시간 예약 및 결제, 택시 호출 및 운송차량 호출",
      ],
      rect_img: "../assets/img/works_img8.png",
      img: "../assets/img/works_info_img8.png",
    },
  ];

  const { id } = useParams();
  useEffect(() => {
    const item = document.getElementById(id);
    setTimeout(() => {
      window.scroll({
        top: item.offsetTop - 100,
        behavior: "smooth",
      });
    }, 100);
  }, [id]);

  return (
    <div id="works_info">
      <div className="container">
        {works_info_list.map((e, i) => (
          <div id={i} className="works_tab_wrapper" key={i}>
            <WorksTab
              title={e.title}
              tag={e.tag}
              desc1={e.desc1}
              desc2={e.desc2}
              desc3={e.desc3}
              rect_img={e.rect_img}
              img={e.img}
            />
            <div className={`works_tab_divider line${i}`}></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorksInfo;
